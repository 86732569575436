export const USER_TYPES = {
    PERSON: { text: "일반회원", value: "PERSON" },
    ADMIN: { text: "관리자", value: "ADMIN" },
};

export const USER_NOTIFY_METHODS = {
    SMS: { text: "SMS(문자메시지)", value: "user.notification:SMS" },
    EMAIL: { text: "이메일", value: "user.notification:EMAIL" },
    PHONE: { text: "전화", value: "user.notification:PHONE" },
};

export const BOARD_YOUTUBE_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "디테일 미술사(작품탐구)" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_REPORT_CATEGORIES = {
    ARTS_HISTORY: { value: "ARTS_HISTORY", text: "양식 미술사" },
    PRICE_TRENDS: { value: "PRICE_TRENDS", text: "가격 동향 분석" },
    WORLD_TRENDS: { value: "WORLD_TRENDS", text: "해외미술 동향" },
    MANAGE_USAGE: { value: "MANAGE_USAGE", text: "미술품 관리와 활용" },
};

export const BOARD_FORMS_CATEGORIES = {
    AUCTION_FORMS: { value: "AUCTION_FORMS", text: "경매 서식" },
    AUCTION_INFSO: { value: "AUCTION_INFOS", text: "경매 정보" },
    ART_NARRATIVE: { value: "ART_NARRATIVE", text: "미술 이야기" },
    CONSULTATIONS: { value: "CONSULTATIONS", text: "아트 컨설팅" },
};

export const BOARD_ARCHIVE_CATEGORIES = {
    EDUCATION: { value: "EDUCATION", text: "교육" },
    LECTURE: { value: "LECTURE", text: "강연 및 포럼" },
    NETWORK: { value: "NETWORK", text: "네트워크" },
    CONSULTATION: { value: "CONSULTATION", text: "컨설팅" },
    ETC: { value: "ETC", text: "기타" },
};

export const FOOTER_SNS_TYPES = {
    twitter: { text: "트위터", value: "twitter" },
    facebook: { text: "페이스북", value: "facebook" },
    instagram: { text: "인스타그램", value: "instagram" },
};

export const branches = ["생활문화센터 하다", "현장지원센터 날다", "현장지원센터 덕풍"];

export const PROGRAM_APPLICATION_STATES = {
    APPLIED: { value: "APPLIED", text: "신청완료" },
    CANCELED: { value: "CANCELED", text: "신청취소" },
    APPROVED: { value: "APPROVED", text: "승인완료" },
    REJECTED: { value: "REJECTED", text: "승인거절" },
};

export const RENTAL_SCHEDULE_STATES = {
    APPLIED: { value: "APPLIED", text: "신청완료" },
    CANCELED: { value: "CANCELED", text: "신청취소" },
    APPROVED: { value: "APPROVED", text: "승인완료" },
    REJECTED: { value: "REJECTED", text: "승인거절" },
};

export const RENTAL_SCHEDULE_USERS = {
    USERNAME: { value: "username", text: "아이디" },
    PHONE: { value: "phone", text: "전화번호" },
    NAME: { value: "name", text: "이름" },
};

export const RENTAL_LIMITATION_TYPES = {
    PER_WEEK: { value: "PER_WEEK", text: "주별 제한" },
    PER_WEEK_ON_BRANCH: { value: "PER_WEEK_ON_BRANCH", text: "지점당 주별 제한" },
};
