export const inputAttrs = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const switchAttrs = {
    inset: true,
    hideDetails: true,
    class: "ma-0 pa-0",
};

export const attrs_table = {
    "disable-sort": true,
    "disable-pagination": true,
    "hide-default-footer": true,
};
